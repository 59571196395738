export const isValidCostCenterReferenceId = (input) => {
    const regex = /^SSC_CC\d{4}$/;
    return regex.test(input);
};
export const isValidProgramId = (input) => {
    const regex = /^SSC_PR\d{4}$/;
    return regex.test(input);
};
export const isValidLocationId = (input) => {
    const regex = /^LOC_\d{5}$/;
    return regex.test(input);
};
export const isValidSupplierId = (input) => {
    const regex = /^S-\d{8}$/;
    return regex.test(input);
};
