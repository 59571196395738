import { type ReactNode } from 'react'
import Head from 'next/head'

import { useEnv } from '@activesg/ui/hooks'

interface DefaultLayoutProps {
  children: ReactNode
}

export const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  const { env } = useEnv()
  return (
    <>
      <Head>
        <title>{env.NEXT_PUBLIC_APP_NAME}</title>
        <link href="/favicon.ico" rel="icon" />
      </Head>

      <main>{children}</main>
    </>
  )
}
