export const isAcyclicGraph = (nodes) => {
    const graph = new Map();
    for (const node of nodes) {
        graph.set(node.name, node.children);
    }
    const visited = new Set();
    const visiting = new Set();
    // DFS
    const visit = (node) => {
        if (visited.has(node))
            return true;
        if (visiting.has(node))
            return false;
        visiting.add(node);
        for (const child of graph.get(node) ?? []) {
            if (!visit(child))
                return false;
        }
        visiting.delete(node);
        visited.add(node);
        return true;
    };
    for (const node of graph.keys()) {
        if (!visit(node))
            return false;
    }
    return true;
};
