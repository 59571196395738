import { atom } from 'jotai';
/**
 * Utility function to set a create a debounce atom.
 *
 * The function returns the following:
 *  - readOnlyCurrentValueAtom: derived read-only atom which represents the current value without debounce.
 *  - isDebouncingAtom: derived atom config to show if we are in debouncing state. State computed when currentValue !== debouncedValue.
 *  - debouncedValueAtom: atom config for debounced value setting
 */
export function atomWithDebounce(initialValue, delayMilliseconds = 200, shouldDebounceOnReset = false) {
    const prevTimeoutAtom = atom(null);
    // DO NOT EXPORT currentValueAtom as using this atom to set state can cause
    // inconsistent state between currentValueAtom and debouncedValueAtom
    const _currentValueAtom = atom(initialValue);
    const isDebouncingAtom = atom(false);
    const clearTimeoutId = (get, set, prevTimeoutAtom) => {
        const prevTimeoutId = get(prevTimeoutAtom);
        if (prevTimeoutId) {
            clearTimeout(prevTimeoutId);
            set(prevTimeoutAtom, null);
        }
    };
    const debouncedValueAtom = atom(initialValue, (get, set, update) => {
        const onDebounceStart = () => {
            set(_currentValueAtom, nextValue);
            set(isDebouncingAtom, true);
        };
        const onDebounceEnd = () => {
            set(debouncedValueAtom, nextValue);
            set(isDebouncingAtom, false);
        };
        // clear previous timeout so that prev event does not fire
        clearTimeoutId(get, set, prevTimeoutAtom);
        const prevValue = get(_currentValueAtom);
        const nextValue = typeof update === 'function'
            ? update(prevValue)
            : update;
        // set current value atom to the current next value without debounce
        onDebounceStart();
        if (!shouldDebounceOnReset && nextValue === initialValue) {
            onDebounceEnd();
            return;
        }
        const nextTimeoutId = setTimeout(() => {
            onDebounceEnd();
        }, delayMilliseconds);
        // set previous timeout atom in case it needs to get cleared
        set(prevTimeoutAtom, nextTimeoutId);
    });
    /** These return atoms are intentionally split so that different components can subscribe to their changes without re-renders */
    return {
        currentValueAtom: atom((get) => get(_currentValueAtom)),
        isDebouncingAtom,
        debouncedValueAtom,
    };
}
