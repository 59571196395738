// endDateTime is null or greater than current date
// startDateTime is less than current date
export function getActiveBlacklists(blacklists) {
    if (!blacklists) {
        return {
            suspension: null,
            termination: null,
        };
    }
    const activeSuspensions = blacklists.filter((blacklist) => blacklist.type === 'SUSPENSION' &&
        blacklist.startDateTime < new Date() &&
        (!blacklist.endDateTime || blacklist.endDateTime > new Date()));
    const activeTerminations = blacklists.filter((blacklist) => blacklist.type === 'TERMINATION' &&
        blacklist.startDateTime < new Date() &&
        (!blacklist.endDateTime || blacklist.endDateTime > new Date()));
    const suspensions = activeSuspensions.sort((a, b) => a.startDateTime.getTime() < b.startDateTime.getTime() ? 1 : -1);
    const terminations = activeTerminations.sort((a, b) => a.startDateTime.getTime() < b.startDateTime.getTime() ? 1 : -1);
    return {
        suspension: suspensions[0] ?? null,
        termination: terminations[0] ?? null,
    };
}
