import { FormLabel } from './FormLabel'
import { Input } from './Input'
import { NumberInput } from './NumberInput'
import { SingleSelect } from './SingleSelect'
import { TagInput } from './TagInput'
import { Textarea } from './Textarea'

export const components = {
  FormLabel,
  Input,
  NumberInput,
  Textarea,
  TagInput,
  SingleSelect,
}
